@import '@angular/material/theming';

/* source: https://material.angular.io/guide/theming-your-components */
@mixin channel-tile-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $accent: map-get($theme, accent);

  .active-title {
    color: mat-color($accent);
  }
}

.card-footer-preview {
  margin-left: -0.4em;
}

.card-footer-masks {
  > * {
    cursor: pointer;
  }

  mat-icon {
    font-size: 1.7em !important;
    margin-right: 4px !important;
  }

  span {
    margin-top: 0.45em !important;
  }

  padding-bottom: 0.4em;
  padding-right: 0.3em;
}
