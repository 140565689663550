@import '@angular/material/theming';

/* source: https://material.angular.io/guide/theming-your-components */
@mixin header-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);

  app-header mat-toolbar {
    background-color: mat-color($primary, header) !important;
    color: mat-color($primary, header-font) !important;
  }
}
