// @import 'margin.scss';
// @import 'responsive-col.scss';

@import '@angular/material/theming';

:root {
  --sidebar-width: 62px;
  --header-height: 65px;

  --primary-color-50: #e1f7fa;
  --primary-color-100: #b4ebf2;
  --primary-color-200: #84dfea;
  --primary-color-300: #55d1e1;
  --primary-color-400: #37c7da;
  --primary-color-500: #27bdd4;
  --primary-color-600: #24adc1;
  --primary-color-700: #2098a7;
  --primary-color-800: #1d848f;
  --primary-color-900: #156164;

  --primary-color-contrast-50: #f5f5f5;
  --primary-color-contrast-100: #e9e9e9;
  --primary-color-contrast-200: #d9d9d9;
  --primary-color-contrast-300: #c4c4c4;
  --primary-color-contrast-400: #9d9d9d;
  --primary-color-contrast-500: #7b7b7b;
  --primary-color-contrast-600: #555555;
  --primary-color-contrast-700: #434343;
  --primary-color-contrast-800: #262626;
  --primary-color-contrast-900: #000000;

  --accent-color-50: '#e1f7fa';
  --accent-color-100: #b4ebf2;
  --accent-color-200: #84dfea;
  --accent-color-300: #55d1e1;
  --accent-color-400: #37c7da;
  --accent-color-500: #27bdd4;
  --accent-color-600: #24adc1;
  --accent-color-700: #2098a7;
  --accent-color-800: #1d848f;
  --accent-color-900: #156164;

  --accent-color-contrast-50: #ffffff;
  --accent-color-contrast-100: #fafafa;
  --accent-color-contrast-200: #f5f5f5;
  --accent-color-contrast-300: #f0f0f0;
  --accent-color-contrast-400: #dedede;
  --accent-color-contrast-500: #c2c2c2;
  --accent-color-contrast-600: #979797;
  --accent-color-contrast-700: #818181;
  --accent-color-contrast-800: #606060;
  --accent-color-contrast-900: #3c3c3c;

  --primary-color: #37c6da;
  --accent-color: #37c6da;
  --accent-color-contrast: #ffffff;

  --link-color: #208cac;

  --global-font: ITCAvantGardeProBk, 'Century Gothic', Arial, Sans-serif;

  /* used for the header only */
  --header-color: #534e4e;
  --header-font-color: white;

  /* masks status */
  --status-draft: #ffcc00;
  --status-online: #32c832;
  --status-coming: #527edb;
  --status-offline: #cd3c14;
}

@font-face {
  font-family: 'HelveticaNeue55';
  src: url(../fonts/EOT/HelvNeue55_W1G.eot) format('eof'), url(../fonts/TTF/HelvNeue55_W1G.ttf) format('ttf'), url(../fonts/WOFF/HelvNeue55_W1G.woff) format('woff'),
    url(../fonts/WOFF2/HelvNeue55_W1G.woff2) format('woff2');
}

@font-face {
  font-family: 'HelveticaNeue75';
  src: url(../fonts/EOT/HelvNeue75_W1G.eot) format('eof'), url(../fonts/TTF/HelvNeue75_W1G.ttf) format('ttf'), url(../fonts/WOFF/HelvNeue75_W1G.woff) format('woff'),
    url(../fonts/WOFF2/HelvNeue75_W1G.woff2) format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'ITCAvantGardeProBk';
  src: url(../fonts/OTF/ITCAvantGardePro-Bk.otf) format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'ITCAvantGardeProBk';
  src: url(../fonts/OTF/ITCAvantGardePro-Md.otf) format('opentype');
  font-weight: bold;
}

html,
body {
  // height: 100%;
  margin: 0;
  font-family: var(--global-font);
  font-size: 1rem;
}

/** Headings **/

h1 {
  font-size: 1.5rem;
  margin: 8px 0 12px;
}

h2 {
  font-size: 1.4rem;
  margin: 4px 0 12px;
  font-weight: normal;
}

h3 {
  font-size: 1.1rem;
  margin: 4px 0 12px;
}

/** link **/
a {
  text-decoration: underline;
}

/** button **/
/*
.mat-button,
.mat-raised-button {
  font-size: 1em;
}

.mat-button {
  border-radius: 4px !important;
}

.mat-icon {
  font-size: 32px;
}

.mat-button:hover .mat-button-focus-overlay,
.mat-button:focus .mat-button-focus-overlay,
.mat-button:active .mat-button-focus-overlay,
.mat-button.active .mat-button-focus-overlay {
  opacity: 0 !important;
}

.mat-button-focus-overlay,
.mat-button-ripple.mat-ripple {
  display: none !important;
}

.mat-raised-button:not([disabled]):active:not([class*='mat-elevation-z']) {
  box-shadow: none;
}*/

/** menu **/

.mat-menu-panel {
  width: 100% !important;
  max-width: none !important;
}

/** table **/

table {
  width: 100%;
  table-layout: fixed;
}

.mat-row:hover {
  background-color: #eee;
}

td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding: 0 0.5em !important;
}

// .mat-cell {
//   // padding-right: 0.5em !important;
// }

td.mat-cell:last-of-type {
  padding-right: 0 !important;
}

.cdk-column-actions {
  text-align: right !important;
}

/** Responsive Material Table **/

mat-table {
  .mat-no-data-row {
    text-align: center;
    font-size: 14px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  span.mobile {
    display: none;
  }

  mat-cell {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .mat-icon {
      font-size: 24px;
      padding: 0;
      vertical-align: middle !important;
    }

    &.mat-column-actions {
      justify-content: flex-end;
      overflow: visible;

      .actions-menu-toggle {
        display: none;
      }

      .actions-menu-items {
        display: flex;
      }
    }
  }
}

.handset {
  mat-table {
    .mat-header-row {
      display: none;
    }

    span.mobile {
      display: none;
    }

    mat-row {
      align-items: flex-start;
      align-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px;

      &:first-of-type {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    mat-cell {
      min-height: 20px;
      padding: 0 !important;
      flex: 0 0 auto;

      &.mat-column-actions {
        flex: 0 0 100%;
        flex-direction: column;
        align-content: stretch;
        justify-content: center !important;
        flex-wrap: wrap;

        .actions-menu-toggle {
          display: inline-block;
        }

        .actions-menu-items {
          display: none;

          &.active {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            right: 20px;
            z-index: 1000;
            transform: translateY(calc(50% + 20px));
            text-align: left;
            background-color: #efefef;
            border-radius: 10px;
            box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
          }
        }
      }

      &.mat-column-name {
        font-weight: bold;
      }
    }
  }
}

/** pane **/

.pane-mask {
  width: 100%;
  height: 100%;
  max-width: initial !important;
}

.pane-video-preview {
  width: 100%;
  height: 100%;
  max-width: initial !important;
  background: rgba($color: black, $alpha: 0.8);

  .mat-dialog-container {
    overflow: hidden;
    background: transparent;
  }
}

// Avoid having a very light gray background on all the site
// .mat-drawer-container {
//   background-color: #fafafa !important;
// }

/** filter **/

.mat-header-cell .filter {
  display: flex;
  margin-left: -1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

/** Generic filter on most list pages (with search field) **/

.filter-container {
  display: inline;
  margin: 2em 0 2em 1em;

  .mat-form-field {
    width: 20%;
  }

  .mat-icon {
    font-size: 1.2em !important;
    vertical-align: bottom;
    padding-right: 0.2em;
    margin-bottom: -3px;
  }
}

.handset {
  .filter-container {
    .mat-form-field {
      width: auto;
    }
  }
}

/** side sheet **/

@keyframes slideDownSideSheetPane {
  0% {
  }
  100% {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 599.98px) and (orientation: portrait) {
  .overlay-side-sheet-pane {
    width: 90vw !important;
    max-width: 90vw !important;
  }
}

@media screen and (max-width: 959.98px) and (orientation: landscape) {
  .overlay-side-sheet-pane {
    width: 80vw !important;
  }
}

.overlay-side-sheet-pane {
  height: 100%;
  width: 40%;
  transform: translateX(100%);
  animation: slideDownSideSheetPane 0.3s forwards 0s ease-in;

  .container {
    height: 100%;
  }

  .mat-dialog-container {
    border-radius: 0;
  }

  .side-sheet-title {
    margin: 2vh 3vw;
    font-size: 1.4em;
    font-weight: normal;
  }

  .side-sheet-form {
    margin: 2vh 3vw;
    min-height: 20vh;
  }

  @media screen and (max-width: 1279px) {
    min-height: 55vh;
  }

  .side-sheet-close {
    text-align: right;
  }

  .side-sheet-actions {
    text-align: right;
    padding-bottom: 24px;
    margin: 5vh 3vw 1vh 3vw;
  }

  .side-sheet-actions > .action-button:not(:last-child) {
    margin-right: 1em;
  }
}

.mat-form-field-label {
  height: 2em !important;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  font-size: 1.2em !important;
}

.mat-input-element {
  line-height: 1em !important;
}

.mat-paginator-page-size-select {
  width: 56px !important;
}

.center-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: left;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.fileover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.timepicker-overlay {
  z-index: 9999 !important;
}

.timepicker-backdrop-overlay {
  z-index: 9999 !important;
}

.timepicker-overlay > ngx-material-timepicker-content > div {
  position: initial !important;
}

.media-player-select {
  .mat-dialog-container {
    padding: 4vh;
  }
}
