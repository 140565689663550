@import '@angular/material/theming';

/* source: https://material.angular.io/guide/theming-your-components */
@mixin mask-tile-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $accent: map-get($theme, accent);

  .card-divider-1 {
    border: 0.2em solid mat-color($accent, status-draft);
    background-color: mat-color($accent, status-draft);
  }

  .card-divider-2 {
    border: 0.2em solid mat-color($accent, status-online);
    background-color: mat-color($accent, status-online);
  }

  .card-divider-4 {
    border: 0.2em solid mat-color($accent, status-coming);
    background-color: mat-color($accent, status-coming);
  }

  .card-divider-8 {
    border: 0.2em solid mat-color($accent, status-offline);
    background-color: mat-color($accent, status-offline);
  }
}
