@import '@angular/material/theming';

/* source: https://material.angular.io/guide/theming-your-components */
@mixin sidebar-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $accent: map-get($theme, accent);

  .mat-nav-list > a {
    border-color: mat-color($accent) !important;
  }
}
