@import '@angular/material/theming';
@import 'app/mask-edition/mask-stepper/mask-stepper-theme.scss';
@import 'app/channels/channel-tile/channel-tile-theme.scss';
@import 'app/general/layout/sidebar/sidebar-theme.scss';
@import 'app/shared/components/mask-tile/mask-tile-theme.scss';
@import 'app/general/layout/header/header-theme.scss';
@import 'app/planning/calendar/mask-block/mask-block-theme.scss';

@include mat-core;

/* For use in src/lib/core/theming/_palette.scss */
$md-app-primary: (
  header: var(--header-color),
  header-font: var(--header-font-color),
  50: var(--primary-color-50),
  100: var(--primary-color-100),
  200: var(--primary-color-200),
  300: var(--primary-color-300),
  400: var(--primary-color-400),
  500: var(--primary-color-500),
  600: var(--primary-color-600),
  700: var(--primary-color-700),
  800: var(--primary-color-800),
  900: var(--primary-color-900),
  contrast: (
    50: var(--primary-color-contrast-50),
    100: var(--primary-color-contrast-100),
    200: var(--primary-color-contrast-200),
    300: var(--primary-color-contrast-300),
    400: var(--primary-color-contrast-400),
    500: var(--primary-color-contrast-500),
    600: var(--primary-color-contrast-600),
    700: var(--primary-color-contrast-700),
    800: var(--primary-color-contrast-800),
    900: var(--primary-color-contrast-900),
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$md-app-accent: (
  status-draft: var(--status-draft),
  status-online: var(--status-online),
  status-coming: var(--status-coming),
  status-offline: var(--status-offline),
  link: var(--link-color),
  50: var(--accent-color-50),
  100: var(--accent-color-100),
  200: var(--accent-color-200),
  300: var(--accent-color-300),
  400: var(--accent-color-400),
  500: var(--accent-color-500),
  600: var(--accent-color-600),
  700: var(--accent-color-700),
  800: var(--accent-color-800),
  900: var(--accent-color-900),
  contrast: (
    link: var(--link-color),
    50: var(--accent-color-contrast-50),
    100: var(--accent-color-contrast-100),
    200: var(--accent-color-contrast-200),
    300: var(--accent-color-contrast-300),
    400: var(--accent-color-contrast-400),
    500: var(--accent-color-contrast-500),
    600: var(--accent-color-contrast-600),
    700: var(--accent-color-contrast-700),
    800: var(--accent-color-contrast-800),
    900: var(--accent-color-contrast-900),
  ),
);

/* reference: https://material.angular.io/guide/typography */

$typography: mat-typography-config(
  $font-family: var(--global-font),
  $body-1: mat-typography-level(14px, 24px, 500),
);

$custom-theme-primary: mat-palette($md-app-primary);
$custom-theme-accent: mat-palette($md-app-accent);
$custom-theme-warn: mat-palette($mat-red);

$custom-theme: mat-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);

/* source: https://material.angular.io/guide/theming-your-components */
@mixin status-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $accent: map-get($theme, accent);

  .status-1 .mat-pseudo-checkbox {
    background-color: mat-color($accent, status-draft) !important;
  }

  .status-2 .mat-pseudo-checkbox {
    background-color: mat-color($accent, status-online) !important;
  }

  .status-4 .mat-pseudo-checkbox {
    background-color: mat-color($accent, status-coming) !important;
  }

  .status-8 .mat-pseudo-checkbox {
    background-color: mat-color($accent, status-offline) !important;
  }
}

@mixin custom-components-theme($theme) {
  @include configuration-mask-stepper-theme($theme);
  @include channel-tile-theme($theme);
  @include sidebar-theme($theme);
  @include status-theme($theme);
  @include mask-tile-theme($theme);
  @include header-theme($theme);
  @include mask-block-theme($theme);

  .mat-progress-bar-background {
    background-color: #eee;
    fill: none;
    left: 0;
  }
}

@include angular-material-theme($custom-theme);
@include angular-material-typography($typography);
@include custom-components-theme($custom-theme);

.warn {
  color: mat-color($custom-theme-warn);
}

.disabled {
  color: mat-color($custom-theme-primary, 100);
  cursor: default;
}

a {
  color: mat-contrast($custom-theme-accent, link);
}
