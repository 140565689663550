@import '@angular/material/theming';

/* source: https://material.angular.io/guide/theming-your-components */
@mixin configuration-mask-stepper-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $accent: map-get($theme, accent);

  #current-step,
  #enabled {
    border-color: mat-color($accent);
  }
}
