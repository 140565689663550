@import './theme.scss';
@import './assets/css/global.scss';

html,
body {
  height: 100%;
}

.main {
  padding: 20px;
}

@media screen and (max-width: 600px) {
  .main {
    padding: 10px !important;
  }
}

input.compact {
  height: 19px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  outline: none;
  font-family: var(--global-font);
  width: 100%;
  padding: 0 0 1px 0;

  &:active,
  &:focus {
    border-bottom: 2px solid var(--accent-color-500);
    padding: 0;
  }
}

// https://github.com/angular/components/issues/8600
.mat-checkbox .mat-checkbox-inner-container {
  overflow: visible !important;
}