@mixin mask-block-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);

  .color {
    background-color: mat-color($primary, header) !important;

    .text-visible,
    .short-mask,
    .icon-visible {
      color: mat-color($primary, header-font) !important;
    }
  }
}
